<template>
  <div class="text-center" v-if="!is_data_ready">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <!--  Content Start -->
  <div class="sabc_contact_outer terms_page" v-else>
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <span v-if="is_data_ready" v-html="termsAndConditions"></span>
        </div>
      </div>
    </div>
  </div>
  <!--  Content end -->
</template>

<script>
export default {
  name: "ticketterms",
  data() {
    return {
      termsAndConditions : [],
      is_data_ready:false,
    };
  },
  components: {},
  mounted() {
    this.getData();
  },
  serverPrefetch() {
    this.getData();
  },
  computed: {},

  metaInfo() {
           if (this.is_data_ready) {
                
                let title = this.termsAndConditions.meta_title;
                let description = this.termsAndConditions.meta_description;
                let keywords = this.termsAndConditions.meta_keywords;

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }
  },

  methods: {
    getData(){
      let compID = this.$route.params.competitionid;

      return this.$axios.get(this.$GetCompetitionsTerms+ '?id=' + compID)
        .then(response => {
          this.$toFullPath(this.$route.fullPath)
            if (response.data) {
                this.is_data_ready = true;
                this.termsAndConditions = response.data.result.terms_and_conditions;
            }
        })
        .catch(error => {
        })
    }
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/contact.scss";
</style>
